@font-face {
  font-family: 'typicons';
  src: url("../bower_components/typicons.font/src/font/typicons.eot");
  src: url("../bower_components/typicons.font/src/font/typicons.eot?#iefix") format("embedded-opentype"), url("../bower_components/typicons.font/src/font/typicons.woff") format("woff"), url("../bower_components/typicons.font/src/font/typicons.ttf") format("truetype"), url("../bower_components/typicons.font/src/font/typicons.svg#typicons") format("svg");
  font-weight: normal;
  font-style: normal; }

.conecta .carousel .carousel-item {
  height: 100% !important; }

#listagem-envios .collection .material-icons {
  margin-top: 15px;
  font-size: 30px; }

.brand-logo {
  margin-top: 7px;
  margin-left: 15px; }
  .brand-logo img {
    width: 180px; }

.padding_m {
  padding: 50px 10px; }

.padding_t {
  padding: 70px 10px 10px 10px; }

.pull-right {
  float: right;
  margin-top: -35px; }

#seleciona-trabalhadores .empresa-info small {
  color: white;
  padding-top: 15px;
  margin-left: 10px; }

#seleciona-trabalhadores .empresa-info h2 {
  color: white;
  font-size: 24px;
  padding-left: 10px;
  margin-top: 0px;
  margin-right: 20px;
  font-weight: 300; }

#seleciona-trabalhadores .empresa-info .voltar {
  float: left;
  position: absolute;
  left: 0;
  top: 5px; }

#seleciona-trabalhadores button {
  margin-left: 5px;
  margin-right: 5px; }

#seleciona-trabalhadores .title .material-icons {
  font-size: 14px;
  color: #60a82a; }

#chat {
  max-height: 650px;
  border: 1px solid #eee; }
  #chat textarea.modal-footer {
    margin-bottom: 0px;
    text-align: left;
    padding-left: 3%;
    padding-right: 10%;
    outline: none;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    height: 100px;
    resize: none;
    line-height: 1.7; }
  #chat .btn-floating.send {
    position: absolute;
    right: 25px;
    bottom: 60px; }
  #chat .modal-content {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    #chat .modal-content .collection {
      margin-top: 0px !important;
      margin-bottom: 0px !important; }
  #chat .secondary-content {
    margin-top: -65px;
    margin-bottom: -28px; }
  #chat .fixed-action-btn.options {
    position: relative;
    margin-top: 15px;
    margin-bottom: -25px; }
  #chat .fixed-action-btn.options .material-icons {
    margin-top: 0px;
    font-size: 30px;
    bottom: auto !important; }
  #chat .message-content {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px; }
    #chat .message-content .material-icons {
      font-size: 16px;
      float: right;
      margin-top: -1px;
      margin-left: 5px; }
  #chat .small-date {
    font-size: 10px;
    display: inline-block; }
  #chat .message-date {
    background: #ccc;
    color: #333;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 3px; }
  #chat .message-content.me {
    text-align: right; }
    #chat .message-content.me .small-date {
      color: white; }
    #chat .message-content.me .message {
      background: #4f8fd8;
      color: white;
      border: 1px solid #4f8fd8;
      display: inline-block;
      padding: 10px;
      max-width: 300px;
      margin-right: 20px; }
  #chat .message-content.you {
    text-align: left; }
    #chat .message-content.you .material-icons {
      display: none; }
    #chat .message-content.you .message {
      border: 1px solid #ccc;
      background: #eee;
      display: inline-block;
      padding: 10px;
      max-width: 300px;
      margin-left: 10px; }
  #chat .chat-content .visualizado {
    display: block;
    font-size: 10px;
    text-align: right;
    padding-bottom: 15px;
    margin-right: 30px; }

#box-send-new .label-to {
  padding-left: 10px;
  padding-top: 10px; }

#box-send-new .to {
  height: 50px;
  overflow-x: auto;
  text-align: left;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px; }
  #box-send-new .to .badge {
    border-radius: 3px;
    margin-bottom: 2px;
    margin-top: 2px;
    float: none !important;
    font-size: 12px;
    display: inline-block;
    position: relative; }

#box-send-new .btn-floating {
  float: right;
  margin-top: 5px; }

#box-send-new #menssage-all {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 0px;
  padding: 10px;
  resize: none; }

#box-send-new.modal.modal-fixed-footer .modal-content {
  height: auto !important; }

#box-send-new input {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 97.5%; }
  #box-send-new input:focus {
    border-bottom-color: #ccc !important;
    box-shadow: 0px 0px 0px transparent; }

#box-send-new .cursor {
  cursor: pointer; }

#box-group-new .label-to {
  padding-left: 10px;
  padding-top: 10px; }

#box-group-new .to {
  min-height: 50px;
  max-height: 100px;
  overflow-x: auto;
  text-align: left;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px; }
  #box-group-new .to .badge {
    border-radius: 3px;
    margin-bottom: 2px;
    margin-top: 2px;
    float: none !important;
    font-size: 12px;
    display: inline-block; }

#box-group-new .btn-floating {
  float: right;
  margin-top: 5px; }

#box-group-new #menssage-all {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 0px;
  padding: 10px;
  resize: none; }

#box-group-new.modal.modal-fixed-footer .modal-content {
  height: auto !important; }

#box-group-new input {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 97.5%; }
  #box-group-new input:focus {
    border-bottom-color: #ccc !important;
    box-shadow: 0px 0px 0px transparent; }

#box-group-new .cursor {
  cursor: pointer; }

.over-list {
  border: 1px solid #eee;
  overflow-y: auto;
  height: calc(100vh - 20px) !important; }

.open-chat {
  cursor: pointer;
  transition: 0.3s; }
  .open-chat:hover {
    transition: 0.3s;
    background: #eee; }

html {
  height: 100%; }

body.home {
  height: 100%;
  background: #2196F3; }

.conecta-home {
  text-align: center;
  padding-top: 10%; }
  .conecta-home .logo {
    width: 90%; }

.menu-conecta {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 90%; }
  .menu-conecta ul {
    display: block;
    padding-left: 0px; }
  .menu-conecta .item-menu {
    cursor: pointer;
    width: calc(100% / 5);
    min-width: 250px;
    padding: 10px 10px 30px 10px;
    border: 3px solid white;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;
    display: inline-block;
    transition: 0.3s;
    background: white;
    border-radius: 5px; }
    .menu-conecta .item-menu .icon {
      font-size: 102px; }
      .menu-conecta .item-menu .icon .typcn {
        transition: 0.3s;
        color: #999; }
    .menu-conecta .item-menu .title {
      text-align: center;
      color: #999;
      font-size: 26px; }
    .menu-conecta .item-menu:hover {
      transition: 0.6s;
      transform: scale(1.1);
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      border-radius: 20px; }
      .menu-conecta .item-menu:hover .icon {
        font-size: 102px; }
        .menu-conecta .item-menu:hover .icon .typcn {
          transition: 0.3s;
          color: #444; }
      .menu-conecta .item-menu:hover .title {
        transition: 0.3s;
        color: #444;
        font-size: 26px; }

.busca-trabalhador .card-panel {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); }

.nav-wrapper .right-content {
  text-align: right; }

#tabs-swipe-demo.tabs {
  height: 64px;
  width: auto !important;
  display: inline-block !important;
  background: transparent !important; }
  #tabs-swipe-demo.tabs .indicator {
    display: none; }
  #tabs-swipe-demo.tabs .tab {
    height: 64px; }
  #tabs-swipe-demo.tabs a {
    color: white  !important;
    padding: 10px 26px; }
    #tabs-swipe-demo.tabs a.active {
      font-weight: 600; }

.user-info-content {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  height: 64px;
  cursor: pointer;
  transition: 0.3s; }
  .user-info-content:hover {
    transition: 0.3s;
    transform: scale(1.1); }
  .user-info-content img {
    border: 2px solid white;
    border-radius: 100px;
    width: 45px; }

.poiter-online {
  width: 10px;
  height: 10px;
  border: 1px solid white;
  background: #666;
  display: inline-block;
  border-radius: 50px;
  position: absolute;
  margin-left: -25px;
  margin-top: 30px; }
  .poiter-online.active {
    background-color: #60a82a; }

#box-send-new .modal-footer {
  display: none; }

.center {
  text-align: center; }

.right-align {
  text-align: right; }

#box-send-group .modal-footer {
  display: none; }

#box-send-group input {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 97.5%; }
  #box-send-group input:focus {
    border-bottom-color: #ccc !important;
    box-shadow: 0px 0px 0px transparent; }

#box-send-group textarea {
  border: 1px solid #ccc;
  height: 8rem; }

.empresa-busca .no-empresas {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center; }
  .empresa-busca .no-empresas h2 {
    font-size: 16px;
    color: #999; }

.empresa-busca .collection-item {
  cursor: pointer;
  transition: 0.3s;
  color: #666; }
  .empresa-busca .collection-item:hover {
    background: #eee;
    transition: 0.3s;
    color: black; }
  .empresa-busca .collection-item .title {
    font-size: 18px;
    display: block;
    margin-bottom: 5px; }

#grupos-trabalhadores .sub-trabalhadores .trab-sub {
  font-size: 10px;
  color: #999; }

#grupos-trabalhadores small {
  font-size: 13px; }

.voltar-btn {
  padding: 5px 10px;
  border: 1px solid #999;
  margin-top: 10px;
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #999;
  transition: .3s; }
  .voltar-btn:hover {
    transition: .3s;
    border-color: #333;
    color: #333; }

.nameOperador {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 5px; }

.paginate {
  padding-right: 30px;
  text-align: right; }
  .paginate .listNumbers {
    display: inline-block; }
    .paginate .listNumbers li {
      text-align: center;
      min-width: 15px;
      display: inline-block;
      margin-right: 3px;
      padding-left: 3px;
      border-radius: 3px; }
      .paginate .listNumbers li a {
        padding: 5px;
        display: block;
        color: #777; }
    .paginate .listNumbers li.active a {
      font-weight: 600;
      color: black; }
  .paginate button {
    transition: .3s;
    color: #777;
    background: transparent;
    border: 0px solid;
    font-weight: 800; }
    .paginate button:focus {
      background: transparent;
      color: black;
      transition: .3s; }
    .paginate button:hover {
      transition: .3s;
      color: black; }

.video-tutorial:hover .text {
  transition: 0.3s;
  transform: scale(1.1);
  padding-left: 6px; }

.video-tutorial:hover .img {
  transition: 0.3s;
  transform: scale(1.1); }

.video-tutorial {
  float: right;
  display: inline-flex;
  margin-top: 14px;
  cursor: pointer; }
  .video-tutorial .img {
    transition: 0.3s;
    height: 27px; }
  .video-tutorial .text {
    transition: 0.3s;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    font-weight: "600";
    padding-top: 0px;
    padding-left: 4px; }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  animation-name: tada; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

#search-empresa {
  text-indent: 35px; }

.busca-trabalhador {
  height: 70px !important; }

.empresa-busca .card .collection {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.32); }

.buscaTrabalhadorEntidade {
  position: relative; }
  .buscaTrabalhadorEntidade i {
    position: relative;
    top: 0px;
    left: 0px;
    color: #FFF;
    margin-left: 15px; }
  .buscaTrabalhadorEntidade #inpurBuscaTrabalhador {
    text-indent: 35px; }
  .buscaTrabalhadorEntidade .btn-sendcollection-item {
    visibility: hidden !important; }

.listaTrabalhadorbusca {
  display: block; }
  .listaTrabalhadorbusca li {
    display: block;
    border-bottom: 1px solid rgba(194, 194, 194, 0.21) !important;
    padding: 5px 0 0 5px;
    cursor: pointer; }
  .listaTrabalhadorbusca li:hover {
    background: #f7f7f7; }
